import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  text: {
    fontSize: 12,
    lineHeight: 16,
    textAlign: 'right',
    backgroundColor: 'transparent',
    paddingVertical: 2,
    marginLeft: 8,
  },
});
