import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  inputContainer: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,

    backgroundColor: 'rgb(204, 204, 204)',
  },
});
